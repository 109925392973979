import React from 'react';


class JustComments extends React.Component {
    constructor(...args) {
      super(...args)
      this.ref = React.createRef()
    }
    render() {
      return (
        <div
          ref={this.ref}
          className="just-comments"
          data-apikey="37d03da0-eb40-45b7-bc55-5c33c1f38183"
        />
      )
    }
    componentDidMount() {
      const s = document.createElement('script')
      s.src = '//just-comments.com/w.js'
      s.setAttribute('data-timestamp', +new Date())
      this.ref.current.appendChild(s)
    }
  }

  export default JustComments;