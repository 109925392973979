import React from 'react';
import {graphql,Link} from 'gatsby';
import Layout from '../components/Layout'
import Comments from '../components/Just-Comments';
import '../css/button.css';


const Template = ({data, pageContext}) => {
    const {next, prev} = pageContext;
    const title = data.markdownRemark.frontmatter.title;
    const html = data.markdownRemark.html
    return (
        <Layout>
            {title}
              <br/>
          <div
            dangerouslySetInnerHTML={{__html:html}}
           />
        <div class="btn-group" role="group">
           {next && <Link to={next.frontmatter.path} className="btn btn-info">
                Next
           </Link>}

           {prev && <Link to={prev.frontmatter.path} className="btn btn-info">
              Prev
           </Link>}
         </div>
           

           <Comments/>
        </Layout>
    )
}

export const query = graphql`
   query($pathSlug: String!){
       markdownRemark(frontmatter: { path: { eq: $pathSlug} }){
           html
           frontmatter{
               title
           }
       }
   }

`

export default Template;